import { LoadableLazy } from '@confluence/loadable';

export { usePremiumDirectBuyStandardExperiment } from './hooks/usePremiumDirectBuyStandardExperiment';

export const RecommendStandardOverPremiumModalContainerLoadable = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-RecommendStandardOverPremiumModalContainer" */ './ui/RecommendStandardOverPremiumModalContainer'
			)
		).RecommendStandardOverPremiumModalContainerWithErrorBoundary,
});
